import React from "react";
import "./ProjectCard.css";

function ProjectCard({title, img, description, link, linkText}) {
  return (
    <div className="project-card" style={{ backgroundImage: `url(${img})` }}>
      <h3>{title}</h3>
      <p>{description}</p>
      {link && <a href={link}>{linkText}</a>}
    </div>
  );
};
export default ProjectCard;