import React, { useState } from 'react';
import { signInWithEmailAndPassword, deleteUser } from 'firebase/auth';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { auth, storage } from '../firebase';

function DeleteAccountForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    async function deleteAll(storageRef) {
        // Delete all files in the folder
        const listResult = await listAll(storageRef);
        listResult.items.forEach(async (itemRef) => {
            await deleteObject(itemRef);
        });

        // Delete all subfolders in the folder
        listResult.prefixes.forEach(async (prefixRef) => {
            await deleteAll(prefixRef);
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(false);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            //delete the users cloud storage folder
            const storageRef = ref(storage, `${user.uid}`);
            await deleteAll(storageRef);

            // If authentication is successful, delete the user
            await deleteUser(user);
            setSuccess(true);
            console.log('Account successfully deleted');
        } catch (error) {
            setError(error.message);
            console.error('Error deleting account:', error);
        }
    };

    return (
        <div>
            <h1>Account Deletion Request</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Delete Account</button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>Account successfully deleted</p>}
        </div>
    );
};

export default DeleteAccountForm;