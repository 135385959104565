import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

const fireabaseConfig = {
    apiKey: "AIzaSyBQJrhclOQegqxXurYcLrn8MnuuV43yGxA",
    authDomain: "engaged-builder-429220-e6.firebaseapp.com",
    projectId: "engaged-builder-429220-e6",
    storageBucket: "engaged-builder-429220-e6.appspot.com",
    messagingSenderId: "565875684734",
    appId: "1:565875684734:web:58f1b01e6df1ec1beeaf2f",
    measurementId: "G-NVE1VMDGT4"
};

const app = initializeApp(fireabaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, storage };