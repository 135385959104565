import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DeleteAccountForm from "./motoroute/DeleteAccountForm";
import Home from "./home/Home";
import "./home/App.css";
import "./firebase";

export default function App() {
    return (
        <div id="app">
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/motoroute/deleteaccount" element={<DeleteAccountForm />} />
                </Routes>
            </Router>
        </div>
    );
}