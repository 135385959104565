import React from "react";
import ProjectCard from "./ProjectCard";

function Home() {
    return (
        <div>
            <h1>Luke Elrod</h1>
            <div className="projects">
                <h2>Projects</h2>
                <ProjectCard title="Motoroute" description="The preferred motorcycle route planner." link="motoroute/deleteaccount" linkText="Delete Account" />
            </div>
        </div>
    );
}

export default Home;